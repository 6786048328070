// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CheckBoxBase } from "@react-native-community/checkbox";
import { toast } from "react-toastify";

export const configJSON = require("./config");

interface CountryData {
  id: string,
  type: string,
  attributes: {
    name: string,
    emoji_flag: string,
    country_code: string
  }
}

export interface ResponseContentData {
  id: string
  data: [];
};

export interface Props {
  navigation?: any;
  id?: string;
  handelClose: any;
  handleOpen: any;
  handelEditEmailModel: any;
  handelEditEmailModelClose: any;
}

interface S {
  userProfileData: any;
  userProfileDataModal: any;
  isPasswordUpdate: Boolean;
  showPopup: boolean;
  showPopupVarification: boolean;
  imageDemo: any;
  image: any;
  myInterestData: any;
  apiCall: boolean;
  showEditEmailMsg: boolean;
  otp: string;
  NewEmail: string;
  ConfirmNewEmail: string;
  email: string;
  verificationCode: string[];
  CountryFlagCode: Array<CountryData>;
  selectOpen: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserProfileModalID: string = "";
  getUserProfileID: string = "";
  getmyInterestDataID: string = "";
  apiEmailLoginCallId: string = "";
  updateUserProfileID: string = "";
  updateUserProfilePasswordID: string = "";
  delete_categories: string = "";
  changeEmail: string = "";
  OtpGave: string = "";
  resendOTP: string = "";
  add_categories: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  postResetPasswordViaEmailAPICallId: string = "";
  updateVarifyPasswordAPICallId: string = "";
  getCountryCodeFlagCallIds: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      userProfileData: {},
      userProfileDataModal: {},
      isPasswordUpdate: false,
      showPopup: false,
      showPopupVarification: false,
      imageDemo: '',
      image: '',
      myInterestData: {},
      apiCall: true,
      showEditEmailMsg: true,
      otp: '',
      NewEmail: '',
      ConfirmNewEmail: '',
      email: '',
      verificationCode: ['', '', '', ''],
      CountryFlagCode: [],
      selectOpen: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.enablePasswordUI = this.enablePasswordUI.bind(this)
    this.disablePasswordUI = this.disablePasswordUI.bind(this)
  }

  async componentDidMount() {

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    switch (apiRequestCallId) {
      case this.getUserProfileID:
        this.handleUserProfileResponse(responseJson);
        break;

      case this.getUserProfileModalID:
        this.handleUserProfileModalResponse(responseJson);
        break;

      case this.getmyInterestDataID:
        this.setState({ myInterestData: responseJson });
        break;

      case this.delete_categories:
        if (responseJson) {
          this.handleDeleteCategoriesResponse();
        }
        break;

      case this.changeEmail:
        if (responseJson.status === 422) {
          toast.error(responseJson.message[0]);
        } else if (responseJson.status === 200) {
          toast.success(responseJson.message[0]);
          this.setState({ showEditEmailMsg: false })
        }
        break;

      case this.OtpGave:
        if (responseJson.status === 422) {
          toast.error(responseJson.message[0]);
        } else if (responseJson.status === 200) {
          toast.success(responseJson.message[0]);
          this.props.handelEditEmailModelClose();
          setTimeout(function() {
            window.location.reload();
          }, 4000);
        }
        break;

      case this.resendOTP:
        if (responseJson.status === 422) {
          toast.error(responseJson.message[0]);
        } else if (responseJson.status === 200) {
          toast.success(responseJson.message[0]);
        }
        break;

      case this.add_categories:
        if (responseJson) {
          this.handleAddCategoriesResponse();
        }
        break;

      case this.updateUserProfileID:
        this.handleUpdateUserProfileResponse(responseJson);
        break;

      case this.updateUserProfilePasswordID:
        this.handleUpdateUserProfilePasswordResponse(responseJson);
        break;

      case this.postResetPasswordViaEmailAPICallId:
        this.handleResetPassowordViaEmailResponse(responseJson)
        break;
      
      case this.updateVarifyPasswordAPICallId:
        this.handleUpdateVarifyPasswordResponse(responseJson)
        break;
      
      case this.getCountryCodeFlagCallIds:
        this.getCountryRes(responseJson)
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  handleResetPassowordViaEmailResponse(responseJson: any) {
    if (responseJson?.status === 200 ) {
      let msg = responseJson?.message[0]
      toast.success(msg);
      this.setState({ showPopup: false, showPopupVarification: true }, () => {
        if(responseJson?.message[0] === "Email updated successfully") {
          this.setState({ showPopupVarification: false });
          this.enablePasswordUI()
        }
    });
    } else {
      let msg = responseJson?.message[0]
      toast.error(msg)
    }
  }

  handleUpdateVarifyPasswordResponse(responseJson: any) {
    if (responseJson) {
      let msg = responseJson?.message[0]
      toast.success(msg);
      this.setState({ showPopup: false, showPopupVarification: false });
    }
  }
  
  handleUserProfileResponse(responseJson: any) {
    if (responseJson && !responseJson.error) {
      this.setState({ userProfileData: responseJson });
    }
  }

  handleUserProfileModalResponse(responseJson: any) {
    if (responseJson && !responseJson.error) {
      this.setState({ userProfileDataModal: responseJson.data.attributes, email: responseJson.data.attributes?.email });
    }
  }

  handleDeleteCategoriesResponse() {
    this.getMyInterestData();
    this.setState({ apiCall: false });
  }

  handleAddCategoriesResponse() {
    this.getMyInterestData();
    this.setState({ apiCall: false });
  }

  handleUpdateUserProfileResponse(responseJson: any) {
    if (responseJson && !responseJson.error) {
      toast.success("Successfully Submitted");
      this.props.handelClose();
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      toast.error("Unsuccessfully Submitted");
    }
  }

  handleUpdateUserProfilePasswordResponse(responseJson: any) {
    if (responseJson.data) {
      toast.success("Successfully Submitted");
      this.props.handelClose();
    } else {
      toast.error("Unsuccessfully Submitted");
    }
  }

  enablePasswordUI() {
    this.setState({
      isPasswordUpdate: true
    })
  }

  disablePasswordUI() {
    this.setState({
      isPasswordUpdate: false
    })
  }

  SaveCategories = () => {
    if (this.state.apiCall === false) {
      toast.success("Please Save Categories");
    } else if (this.state.apiCall === true) {
      this.props.handleOpen()

    }
  }

  handleOpen = () => {
    this.props.handelClose();
    this.props.handleOpen();
  };

  getMyInterestData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmyInterestDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyInterestDataAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  // get api for user profile data 
  getUserProfileDataModal() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileModalID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileModalAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  getUserProfileData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // update api for user profile data 
  updateProfileData (user: any) {
    var data = new FormData()
    data.append("first_name", user.first_name)
    data.append("last_name", user.last_name)
    // data.append("email", user.email_id)
    // data.append("full_phone_number", user.Country_Code + user.Phone_Number)
    data.append("full_name", user.user_name)
    if (this.state.image) {
      data.append("avatar", this.state.image, this.state.image.name)
    }

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.updateUserProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateMyProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  // update api for user profile password 
  updateProfilePassword(user: any) {
    var data = JSON.stringify({
      "old_password": user.old_password,
      "password": user.password,
      "password_confirmation": user.password_confirmation
    });

    const header = {
      token: localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.updateUserProfilePasswordID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateMyProfilePasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  Add_Categories = (id: number) => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "category_ids": [id],
      "name": "test category"
    };

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.add_categories = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account/update_categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  Delete_Categories = (id: number) => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "remove_category_ids": [id],
    };

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.delete_categories = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account/update_categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ChangeyourEmail = () => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "new_email": `${this.state.NewEmail}`,
      "confirm_new_email": `${this.state.ConfirmNewEmail}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeEmail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/profiles/change_email`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  OtpSubmit = () => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "otp": parseInt(this.state.otp)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OtpGave = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/profiles/verify_otp`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ResendOTP = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOTP = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleInputChange = (index: number, value: string) => {
    if (/^\d$/.test(value)) {
      const updatedCode = [...this.state.verificationCode];
      updatedCode[index] = value;
      this.setState({ verificationCode: updatedCode }, () => {
        if (index < this.state.verificationCode.length - 1) {
          const nextInput = document.getElementById(`input-${index + 1}`);
          nextInput?.focus();
        }
      });
    }
  };

  handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace") {
      const updatedCode = [...this.state.verificationCode];
      updatedCode[index] = ""; 
      this.setState({ verificationCode: updatedCode }, () => {
        if (index > 0) {
          const prevInput = document.getElementById(`input-${index - 1}`);
          prevInput?.focus();
        }
      });
    }
  };

  postResetPasswordViaEmail = () => {
    let payload = {
      "email": this.state.email,
    }
    this.doResetPasswordViaEmail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.resetPasswordViaEmailEndPoint,
      body: JSON.stringify(payload),
    });
  }

  isCountryMatch = (country: any) => {
    return country.id === 'US' || country.id === 'CA';
  };

  doResetPasswordViaEmail = (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.postResetPasswordViaEmailAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateVarifyPassword = (pin: string) => {
    let body = {
      pin: Number(pin),
    };
    this.doResetPasswordViaEmail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.varifyPasswordEndPoint,
      body: JSON.stringify(body),
    });
  }

  doVarifyPassword = (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.updateVarifyPasswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  CountryCodeApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCountryCodeFlagCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CountryFlagApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCountryRes = (responseJSON: ResponseContentData) => {
    this.setState({ CountryFlagCode: responseJSON.data })
  }

  handleSelectOpen = () => {
    this.setState({ selectOpen: true })
  };

  handleSelectClose = () => {
    this.setState({ selectOpen: false })
  };

}


// Customizable Area End